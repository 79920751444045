import { render, staticRenderFns } from "./Access.vue?vue&type=template&id=f2c7fff6&scoped=true&"
import script from "./Access.vue?vue&type=script&lang=ts&"
export * from "./Access.vue?vue&type=script&lang=ts&"
import style0 from "./Access.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Access.vue?vue&type=style&index=1&id=f2c7fff6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2c7fff6",
  null
  
)

export default component.exports