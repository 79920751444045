









































import { Component, Vue } from "vue-property-decorator";
import { getToken } from "@/utils/auth";

@Component({
	name: "access"
})
export default class Access extends Vue {
	private parentData = {
		titleName: "服务订阅",
		dec: "100+定制化数据服务，按照您的需要订阅付费",
		bannerImg: require("../../assets/images/banner.png"),
		img: require("../../assets/images/banner_icon2.png"),
		imgWidth: "379px",
		imgHeight: "256px"
	};

	private tableData = [
		{
			name: "智慧消防项目招标信息",
			info:
				"一城依托在消防行业的多年经验及渠道，不定期推送行业项目招标资源，并协助提供招标方案，其中，智慧消防云服务平台、智慧消防运营管理平台、智慧消防客服系统已全面开放免费使用，强大的系统功能，为招投标提供强有力的竞争力。",
			price: "100.00/年"
		},
		{
			name: "智慧消防项目安装报表",
			info:
				"一城针对消防项目设备安装情况，实时汇总推送报表，其中包含安装日报、安装周报、安装月报，项目进度一目了然，对进度风险进行及时调整；可分项目推送，可汇总推送。",
			price: "1.00/条",
			data: require("../../assets/images/table.png")
		},
		{
			name: "智慧消防项目隐患推送",
			info:
				"一城智慧消防利用窄带物联网、云计算、大数据、人工智能等技术对项目设备进行实时监控，一旦产生异常数据，或存在消防安全隐患，系统自动推送至安全责任人，第一时间获得隐患信息，为项目形成一道安全保障线；结合一城防误报算法，提供更精准的隐患推送服务。",
			price: "1.00/条"
		},
		{
			name: "智慧消防三维可视化服务",
			info:
				"一城自主研发建模引擎，极大的缩短了建模时间，成本仅是现有市场价格的五分之一。通过对建筑、消防设施、各类传感器等设备还原，一旦发现设备故障或者险情告警，系统会自动跳转事发位置，极大的提高了处置的准确性和及时性。",
			price: "200.00/年"
		}
	];

	private created() {
		this.$emit("getChildrenData", this.parentData);
	}

	private subscribe() {
		console.log(getToken());
		if (!getToken()) {
			this.$router.push({ path: `/login?redirect=${this.$route.path}` });
		}
	}
}
